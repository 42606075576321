import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "m19.607 5.121-6.536 6.536 6.536 6.535a1 1 0 0 1-1.32 1.498l-.095-.083-6.535-6.536-6.536 6.536a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.415l6.536-6.535L3.707 5.12a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.414 0l6.536 6.535 6.535-6.535a1 1 0 0 1 1.415 1.414"
    }, null, -1)
  ])))
}
export default { render: render }